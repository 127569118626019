import * as Sentry from '@sentry/react'

// Sentry Documentation https://docs.sentry.io/platforms/javascript/
// Common issues: https://gist.github.com/impressiver/5092952

const allowUrls = [/members.junglescout.com/i, /members.junglescout.cn/i]

const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // Jungle Scout customs:
  /\.wistia\.com/i,
  /cnd\.pendo\.io/i,
  /js\.stripe\.com/i,
  /widget\.trustpilot\.com/i
]

const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',

  /**
   * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
   * This can also be filtered by enabling the browser extension inbound filter
   */
  "TypeError: can't access dead object",
  /**
   * React internal error thrown when something outside react modifies the DOM
   * This is usually because of a browser extension or Chrome's built-in translate
   */
  "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",

  // Jungle Scout customs:
  /window\.optimizely is undefined/i,
  /ResizeObserver loop limit exceeded/i,
  /ResizeObserver loop completed/i,
  /fbq is not defined/i,
  /out of memory/i,
  /Permission denied to access property/,
  "TypeError: Cannot read properties of undefined (reading 'addEventListener')",
  'AbortError: The user aborted a request.',
  'cancelled'
]

export function initSentry() {
  if (window.Sentry) {
    // already initialized
    return
  }

  if (!process.env.REACT_APP_SENTRY_DSN) {
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.SENTRY_RELEASE_ID || '1.0.0',
    tags: {
      git_commit: 'gitCommit',
      userLevel: 'editor'
    },
    environment: process.env.REACT_APP_JUNGLE_ENV,
    tracesSampleRate: 1.0,
    allowUrls,
    denyUrls,
    ignoreErrors
  })
  window.Sentry = Sentry
}

/**
 * Logs a Sentry exception
 * @param {Error} ex - Exception Error
 * @param {{tags: Object, extra: Object, contexts: Object, user: Object, level: 'info'|'debug'|'warning'|'error'}} context
 */
export function logExceptionNoConsole(ex, context) {
  window.Sentry?.captureException(ex, context)
}

/**
 * Logs a Sentry exception and also logs as an error on the console
 * @param {Error} ex - Exception Error
 * @param {{tags: Object, extra: Object, contexts: Object, user: Object, level: 'info'|'debug'|'warning'|'error'}} context
 */
export function logException(ex, context) {
  logExceptionNoConsole(ex, context)
  /* eslint no-console:0 */
  console.error(`sentry.logException => ${ex}`)
}

export function setSentryUserData(user) {
  if (!user || !window.Sentry) {
    return
  }

  window.Sentry.setUser({
    id: user.id
  })

  window.Sentry.setTag('page_locale', user.selected_language)
}

export function setSentryMWSData({ amazon_seller_account_id, marketplace_id }) {
  if (!window.Sentry) {
    return
  }

  if (amazon_seller_account_id) {
    window.Sentry.setTag('amazon_seller_account_id', amazon_seller_account_id)
  }

  if (marketplace_id) {
    window.Sentry.setTag('marketplace_id', marketplace_id)
  }
}
